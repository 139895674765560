const ROUTES = {
  home: '/',
  treatments: '/tratamientos',
  aestheticTreatments: '/tratamientos-belleza',
  reagenda: '/reagenda',
  checkout: {
    clinics: '/checkout/clinicas',
    type: '/checkout/tipo-cita',
    treatments: '/checkout/treatments',
    schedule: '/checkout/agenda',
    contactForm: '/checkout/contactform',
    confirmation: '/checkout/confirmation',
    start: '/checkout/start',
    startBlackFriday: '/checkout/startblackfriday',
  },
  clinics: '/clinicas',
  aboutUs: '/quienes-somos',
  blog: '/blog',
  dashboard: {
    login: '/dashboard/login',
    crisalix: '/dashboard/crisalix',
    menu: '/dashboard/menu',
    agenda: '/dashboard/agenda',
    reagenda: '/dashboard/reagenda',
    checkOut: '/dashboard/checkout',
    checkIn: {
      treatments: '/dashboard/checkin/treatments',
      agenda: '/dashboard/checkin/agenda',
      thankYou: '/dashboard/checkin/welcome',
      contactForm: '/dashboard/checkin/treatments',
      badRequest: '/dashboard/checkin/badRequest',
      confirmation: '/dashboard/checkin/confirmation',
    },
    schedule: '/dashboard/schedule',
  },
  crm: {
    authentication: '/crm',
    menu: '/crm/menu',
  },
  statics: {
    termsAndConditions: '/aviso-legal',
    privacyPolicy: '/politica-de-privacidad',
  },
  landings: {
    pv: '/start-landing',
  },
  hair: {
    home: '/hair',
    multistep: {
      start: '/hair/multistep/start',
      since: '/hair/multistep/since',
      area: '/hair/multistep/area',
      symptoms: '/hair/multistep/symptoms',
      previousTreatments: '/hair/multistep/previous-treatments',
      age: '/hair/multistep/age',
      allergy: '/hair/multistep/allergy',
      medication: '/hair/multistep/medication',
      previousIllness: '/hair/multistep/previous-illness',
      paternity: '/hair/multistep/paternity',
      extraInfo: '/hair/multistep/extra-info',
      agenda: '/hair/multistep/agenda',
    },
  },
  derma: {
    home: '/',
    precios: '/precios',
    clinics: '/clinicas',
    aboutUs: '/quienes-somos',
    privacyPolicy: '/politica-de-privacidad',
    termsAndConditions: '/aviso-legal',
    multistep: {
      start: '/multistep/start',
      pains: '/multistep/pains',
      symptoms: '/multistep/symptoms',
      skinType: '/multistep/skin-type',
      skinSensibility: '/multistep/skin-sensitivity',
      skinColor: '/multistep/skin-color',
      secondaryConcern: '/multistep/secondary-concern',
      allergy: '/multistep/allergy',
      illness: '/multistep/illness',
      medication: '/multistep/medication',
      lactating: '/multistep/lactancy',
      gender: '/multistep/gender',
      age: '/multistep/age',
      routine: '/multistep/routine',
      routineProducts: '/multistep/routine-products',
      routineTime: '/multistep/routine-time',
      sunScreen: '/multistep/sunscreen',
      pictures: '/multistep/pictures',
      extraInfo: '/multistep/extra-info',
      form: '/multistep/form',
      payment: '/multistep/payment',
      thankyou: '/multistep/thank-you',
      planes: '/multistep/planes',
      output: {
        pain: '/multistep/output-pain',
        skinType: '/multistep/output-skin-type',
        skinColor: '/multistep/output-skin-color',
        secondaryConcern: '/multistep/output-secondary-concern',
        skinCare: '/multistep/output-skin-care',
        pictures: '/multistep/output-pictures',
      },
    },
    diagnostico: {
      home: '/diagnostico',
      seguimiento: '/diagnostico/seguimiento',
      subscription: '/diagnostico/subscription',
    },
  },
  truehair: process.env.NEXT_PUBLIC_TRUEHAIR_URL,
};

export default ROUTES;
